import { defineStore } from 'pinia';
import storeId from '@/store/storeId';
import { getLocalStorage, StorageConstant } from '@/ui/hooks/storageHook';
import DistributorService from '@/application/services/distributor/DistributorService';
import { arrayOrderBy } from '@/ui/hooks/commonFunction';

const enableSortMultiple = false;

export default defineStore({
  id: storeId.DISTRIBUTOR,
  state: () => ({
    isLoading: false,
    currentDistId: 0,
    distributor: {} as any,
    referenceUsers: [] as any[],
    userName: '',
    sortList: [] as any[],
    keySearch: '',
  }),
  getters: {
    sortDataOrderByKey() {
      let result = {};
      const sortLength = this.sortList?.length;
      if (sortLength > 0) {
        const isShowIndex = sortLength > 1;
        result = this.sortList.reduce(
          (previousValue, currentValue: any, index) => {
            previousValue = {
              ...previousValue,
              ...{
                [currentValue.key]: {
                  sortIndex: isShowIndex ? index + 1 : null,
                  orderBy: currentValue.orderBy,
                },
              },
            };
            return previousValue;
          },
          {}
        );
      }
      return result;
    },
  },
  actions: {
    async initData() {
      this.isLoading = true;

      this.currentDistId = parseInt(
        getLocalStorage(StorageConstant.DISTRIBUTOR_ID) || '0'
      );
      await Promise.all([
        this.getAllReferenceUsers(this.currentDistId),
        this.getDistributorDetail(this.currentDistId),
      ]);

      this.isLoading = false;
    },
    async getAllReferenceUsers(distId) {
      try {
        const response =
          await DistributorService.getInstance().getAllReferenceUsers(distId);

        if (response?.result) {
          this.referenceUsers =
            arrayOrderBy(response.result, 'creationDate', 'desc') || [];
        }

        return response.result;
      } catch (error) {
        console.log('🚀 ~ authenticate ~ error', error);
      }
    },

    async getDistributorDetail(distId) {
      try {
        const response = await DistributorService.getInstance().getById(distId);

        if (response?.result) {
          this.distributor = response.result;
          console.log(this.distributor, '-------');
        }
        // return response.result;
      } catch (error) {
        console.log('🚀 ~ getDistributorDetail ~ error', error);
      }
    },
    onSortFilteredTasks() {
      if (this.sortList?.length > 0) {
        const iterate = [...this.sortList.map((o: any) => o.key)];
        const order = [...this.sortList.map((o: any) => o.orderBy)];
        this.referenceUsers = arrayOrderBy(this.referenceUsers, iterate, order);
      } else {
        this.referenceUsers = arrayOrderBy(
          this.referenceUsers,
          'creationDate',
          'desc'
        );
      }
    },

    genSortDataList(sortObj) {
      console.log('🚀 Hyrin ~ genSortDataList ~ sortObj', sortObj);
      const { key, name } = sortObj;
      let orderBy = 'asc';
      let sortData = [] as any[];
      const currentSortData: any = this.sortList;
      if (enableSortMultiple) {
        const indexExisted = currentSortData.findIndex(
          (o: any) => o.key == key
        );
        if (indexExisted > -1) {
          const currentOrderBy = currentSortData[indexExisted].orderBy;
          if (currentOrderBy == 'desc') {
            sortData = sortData.filter((o: any) => o.key !== key);
          } else {
            orderBy = currentOrderBy == 'asc' ? 'desc' : 'asc';
            sortData[indexExisted] = { key, name, orderBy };
          }
        } else {
          sortData = [...sortData, { key, name, orderBy }];
        }
      } else {
        const indexExisted = currentSortData.findIndex((o) => o.key == key);
        if (indexExisted > -1) {
          const currentOrderBy = currentSortData[indexExisted].orderBy;
          if (currentOrderBy == 'asc') {
            sortData = [];
          } else {
            orderBy = currentOrderBy == 'desc' ? 'asc' : 'desc';
            sortData = [{ key, name, orderBy }];
          }
        } else {
          sortData = [{ key, name, orderBy: 'desc' }];
        }
      }
      this.sortList = sortData;

      this.onSortFilteredTasks();
    },
  },
});
