<template>
  <tr
    :id="idTask"
    class="h-max px-1 py-2 border-b border-gray-50"
    :class="[isHighLight ? 'colorNewTask' : '', customClass]"
    scoll
    @click="$emit('click')"
  >
    <slot></slot>
  </tr>
</template>
<script>
import { onMounted, watch } from 'vue';

export default {
  name: 'SynCollapse',
  components: {},
  props: {
    isSub: {
      type: Boolean,
      default: false,
    },
    idTask: {
      type: [Number, String],
      default: 0,
    },
    isHighLight: {
      type: Boolean,
      default: false,
    },
    hover: {
      type: String,
      default: 'hover:bg-current-50',
    },
    customClass: {
      type: String,
      default: '',
    },
    isScollToRow: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['click'],
  setup: (props) => {
    onMounted(() => {
      checkHighlight();
    });
    watch(
      () => props.idTask,
      () => {
        checkHighlight();
      }
    );
    watch(
      () => props.isScollToRow,
      () => {
        checkHighlight();
      }
    );

    const checkHighlight = () => {
      if (props.isHighLight) {
        let el = document.getElementById(props.idTask);
        el?.scrollIntoView({ block: 'center' });
      }
    };

    return {};
  },
};
</script>

<style>
@keyframes change {
  0% {
    background-color: white;
  }
  35% {
    background-color: #e0f1f2;
  }
  70% {
    background-color: #f8ffff;
  }
  100% {
    background-color: white;
  }
}

.colorNewTask {
  display: table-row;
  width: 12rem;
  animation-name: change;
  animation-duration: 1.5s;
}
</style>
