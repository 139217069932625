<template>
  <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      viewBox="0 0 512 512"
      style="enable-background: new 0 0 512 512"
      xml:space="preserve"
  >
                <g>
                  <g
                      xmlns="http://www.w3.org/2000/svg"
                  >
                    <g>
                      <g>
                        <path
                            d="m190.126 237.813h-49.771c-27.125 0-49.188-22.063-49.188-49.188v-24.883c0-8.834 7.166-16 16-16s16 7.166 16 16v24.885c0 9.478 7.708 17.188 17.188 17.188h49.771c9.478 0 17.188-7.708 17.188-17.188v-49.771c0-9.478-7.708-17.188-17.188-17.188h-49.771c-5.489 0-10.699 2.656-13.937 7.115-5.199 7.147-15.23 8.73-22.344 3.552-7.157-5.188-8.74-15.187-3.552-22.345 9.229-12.727 24.124-20.322 39.834-20.322h49.771c27.125 0 49.188 22.063 49.188 49.188v49.771c-.003 27.125-22.064 49.186-49.189 49.186z"

                        ></path>
                      </g>
                      <g>
                        <path
                            d="m372.645 420.334h-49.771c-27.125 0-49.188-22.063-49.188-49.188v-49.771c0-27.125 22.063-49.188 49.188-49.188h49.771c27.125 0 49.188 22.063 49.188 49.188v49.771c0 27.125-22.063 49.188-49.188 49.188zm-49.771-116.145c-9.478 0-17.188 7.708-17.188 17.188v49.771c0 9.478 7.708 17.188 17.188 17.188h49.771c9.478 0 17.188-7.708 17.188-17.188v-49.771c0-9.478-7.708-17.188-17.188-17.188z"

                        ></path>
                      </g>
                      <g>
                        <path
                            d="m190.126 420.334h-49.771c-27.125 0-49.188-22.063-49.188-49.188v-49.771c0-27.125 22.063-49.188 49.188-49.188h49.771c27.125 0 49.188 22.063 49.188 49.188v49.771c-.002 27.125-22.063 49.188-49.188 49.188zm-49.771-116.145c-9.478 0-17.188 7.708-17.188 17.188v49.771c0 9.478 7.708 17.188 17.188 17.188h49.771c9.478 0 17.188-7.708 17.188-17.188v-49.771c0-9.478-7.708-17.188-17.188-17.188z"

                        ></path>
                      </g>
                      <g>
                        <path
                            d="m330.874 235.313c-1.542 0-3.104-.22-4.657-.687-31.418-9.532-52.531-38.02-52.531-70.886 0-40.845 33.229-74.074 74.074-74.074s74.074 33.231 74.074 74.076c0 8.834-7.166 16-16 16s-16-7.166-16-16c0-23.198-18.876-42.074-42.074-42.074s-42.074 18.876-42.074 42.074c0 18.374 12.26 34.938 29.822 40.26 8.459 2.562 13.229 11.501 10.667 19.957-2.091 6.908-8.446 11.354-15.301 11.354z"

                        ></path>
                      </g>
                      <g>
                        <path
                            d="m376.438 230.25c-5.562 0-10.97-2.906-13.916-8.083-4.375-7.689-1.687-17.459 5.99-21.822 3.386-1.926 6.5-4.311 9.239-7.104 6.208-6.291 16.333-6.364 22.635-.156 6.291 6.208 6.355 16.343.156 22.635-4.813 4.875-10.27 9.062-16.198 12.437-2.5 1.417-5.22 2.093-7.906 2.093z"

                        ></path>
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
</template>
