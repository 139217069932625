import { defineStore } from 'pinia';
import storeId from '@/store/storeId';
import UserService from '@/application/services/user/UserService';

export default defineStore({
  id: storeId.user,
  state: () => ({
    isLoading: false,
    userInfo: {} as any,
    fullName: '',
    userName: '',
  }),
  getters: {},
  actions: {
    async getCurrentUser() {
      try {
        this.isLoading = true;
        const response = await UserService.getInstance().getCurrentUser();
        if (response.result) {
          this.userInfo = response.result;
          this.fullName =
            response.result.lastName + ' ' + response.result.firstName;
          this.userName = response.result.userName;
        } else {
          this.isLoading = false;
        }
      } catch (err) {
        this.isLoading = false;
        console.log(' :', err);
      }
    },
    async updateProfile(data) {
      const { id, firstName, lastName, email, phone, userName } = data;
      await UserService.getInstance()
        .updateUserProfile({ id, firstName, lastName, email, phone, userName })
        .then(() => {
          this.getCurrentUser();
        })
        .catch((err) => {
          console.log('update profile fail :', err);
        });
    },

    async updateUserPassword(data) {
      const { currentPassword, newPassword } = data;
      this.isLoading = true;
      await UserService.getInstance()
        .updateUserPassword({ currentPassword, newPassword })
        .then((result) => {
          this.isLoading = false;
          console.log('result:', result);
        })
        .catch((err) => {
          console.log('change password:', err);
        });
    },
  },
});
