<template>
  <div class="flex-center w-full h-full space-x-1 items-center">
    <div class="animate-bounce" style="animation-delay: 0.1s">
      <SynIcon name="tictop-logo" class="h-14 w-14" />
    </div>
    <div class="flex justify-center space-x-1">
      <div class="animate-bounce" style="animation-delay: 0.2s">
        <SynIcon name="character-logo-t" custom-class="h-12 w-8" />
      </div>
      <div class="animate-bounce" style="animation-delay: 0.3s">
        <SynIcon name="character-logo-i" custom-class="h-12 w-8" />
      </div>
      <div class="animate-bounce" style="animation-delay: 0.4s">
        <SynIcon name="character-logo-c" custom-class="h-12 w-8" />
      </div>
      <div class="animate-bounce" style="animation-delay: 0.5s">
        <SynIcon name="character-logo-t" custom-class="h-12 w-8" />
      </div>
      <div class="animate-bounce" style="animation-delay: 0.6s">
        <SynIcon name="character-logo-o" custom-class="h-12 w-8" />
      </div>
      <div class="animate-bounce pl-1" style="animation-delay: 0.7s">
        <SynIcon name="character-logo-p" custom-class="h-12 w-8" />
      </div>
    </div>
    <!-- <div class="animate-bounce" style="animations-delay: 0.2s">
      <SynIcon name="character-logo-t" class="h-28 w-8" />
    </div>
    <div class="animate-bounce" style="animations-delay: 0.3s">
      <SynIcon name="character-logo-i" class="h-9 w-8" />
    </div>
    <div class="animate-bounce" style="animations-delay: 0.4s">
      <SynIcon name="character-logo-c" class="h-12 w-8" />
    </div>
    <div class="animate-bounce" style="animations-delay: 0.5s">
      <SynIcon name="character-logo-t" class="h-12 w-8" />
    </div>
    <div class="animate-bounce" style="animations-delay: 0.6s">
      <SynIcon name="character-logo-o" class="h-12 w-8" />
    </div>
    <div class="animate-bounce" style="animations-delay: 0.7s">
      <SynIcon name="character-logo-p" class="h-12 w-8" />
    </div> -->
    <div
      class="animate-bounce flex items-end mt-10 p-1"
      style="animation-delay: 0.8s"
    >
      <div class="h-12">
        <div class="w-2 h-2 rounded-full bg-current-400"></div>
      </div>
    </div>
    <div
      class="animate-bounce flex items-end mt-10 p-1"
      style="animation-delay: 0.9s"
    >
      <div class="h-12">
        <div class="w-2 h-2 rounded-full bg-current-400"></div>
      </div>
    </div>
    <div
      class="animate-bounce flex items-end mt-10 p-1"
      style="animation-delay: 1s"
    >
      <div class="h-12">
        <div class="w-2 h-2 rounded-full bg-current-400"></div>
      </div>
    </div>
  </div>
</template>
