/* eslint-disable no-unused-vars */
export enum StorageConstant {
  LANGUAGE = 'LANGUAGE',
  TOKEN = 'TOKEN',
  REFRESH_TOKEN = 'REFRESH_TOKEN',
  LOCATION = 'LOCATION',
  DISTRIBUTOR_ID = 'DISTRIBUTOR_ID',
  USER_ID = 'USER_ID',
}

export const getLocalStorage = (item) => {
  return localStorage.getItem(item) || '';
};
export const setLocalStorage = (item, value) => {
  localStorage.setItem(item, value);
};
export const removeLocalStorage = (item) => {
  localStorage.removeItem(item);
};
