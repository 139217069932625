import { createRouter, createWebHistory } from 'vue-router';
import publicRoutes from '@/ui/router/public-routes';
import notFoundRoutes from '@/ui/router/not-found-routes';
import privateRoutes from '@/ui/router/private-routes';

const router = createRouter({
  history: createWebHistory(),
  routes: publicRoutes,
});
export let fullRouteByPermission = [] as any[];

export const filterRoutes = () => {
  const routes = getRouterByPermission(privateRoutes);
  routes.forEach((route) => {
    router.addRoute(route);
  });
  notFoundRoutes.forEach((route) => {
    router.addRoute(route);
  });

  fullRouteByPermission = [
    ...fullRouteByPermission,
    ...routes,
    ...notFoundRoutes,
  ];

  return new Promise((resolve) => {
    resolve(true);
  });
};

const getRouterByPermission = (routes) => {
  const res = [] as any[];
  routes.forEach((route) => {
    const tmp = { ...route };

    if (tmp.children) tmp.children = getRouterByPermission(tmp.children);

    res.push(tmp);
  });
  return res;
};
export default router;
