<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:svgjs="http://svgjs.com/svgjs"
    width="512"
    height="512"
    x="0"
    y="0"
    viewBox="0 0 511.999 511.999"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
  >
    <g>
      <g>
        <g>
          <path
            d="M472.019,365.888h-14.903v-18.818c0-44.556-36.249-80.803-80.804-80.803H274.35v-69.593h44.7
			c22.045,0,39.98-17.935,39.98-39.981V59.645c0-22.045-17.935-39.981-39.98-39.981H199.623c-22.045,0-39.98,17.935-39.98,39.981
			v97.047c0,22.045,17.935,39.981,39.98,39.981h44.7v69.593H136.354c-44.556,0-80.803,36.248-80.803,80.803v18.818h-15.57
			C17.935,365.888,0,383.823,0,405.869v46.486c0,22.045,17.935,39.98,39.981,39.98h61.166c22.045,0,39.98-17.935,39.98-39.98
			v-46.486c0-22.046-17.935-39.981-39.98-39.981H85.578v-18.818c0-27.997,22.778-50.775,50.775-50.775h107.969v69.593h-15.569
			c-22.046,0-39.981,17.935-39.981,39.981v46.486c0,22.045,17.935,39.98,39.981,39.98h61.166c22.045,0,39.98-17.935,39.98-39.98
			v-46.486c0-22.046-17.935-39.981-39.98-39.981H274.35v-69.593h101.963c27.998,0,50.776,22.778,50.776,50.775v18.818h-16.237
			c-22.045,0-39.98,17.935-39.98,39.981v46.486c0,22.045,17.935,39.98,39.98,39.98h61.166c22.046,0,39.981-17.935,39.981-39.98
			v-46.486C512,383.823,494.065,365.888,472.019,365.888z"
          ></path>
        </g>
      </g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </g>
  </svg>
</template>
