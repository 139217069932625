<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:svgjs="http://svgjs.com/svgjs"
    width="512"
    height="512"
    x="0"
    y="0"
    viewBox="0 0 512 512"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
  >
    <g>
      <path
        d="m437.089844 74.980469c-48.308594-48.351563-112.539063-74.980469-180.855469-74.980469-61.578125 0-120.164063 21.808594-166.527344 61.699219l-49.601562-48.738281-9.9375 168.507812 171.300781-12.667969-48.609375-45.027343c29.421875-23.101563 65.570313-35.667969 103.375-35.667969 92.492187 0 167.742187 75.316406 167.742187 167.894531s-75.25 167.898438-167.742187 167.898438c-42.464844 0-82.976563-15.917969-114.078125-44.816407-30.933594-28.75-49.796875-67.6875-53.109375-109.644531l-1.09375-13.816406h-87.953125l.949219 15.894531c8.066406 134.851563 120.199219 240.484375 255.285156 240.484375 68.316406 0 132.546875-26.628906 180.855469-74.980469 48.304687-48.351562 74.910156-112.640625 74.910156-181.019531s-26.605469-132.667969-74.910156-181.019531zm0 0"
      ></path>
    </g>
  </svg>
</template>
