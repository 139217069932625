<template>
  <div class="rounded flex-center">
    <div class="w-full h-full flex-center flex-col relative">
      <SynIcon
        :name="iconName"
        :class="orderBy ? 'fill-main' : ''"
        custom-class="h-2 w-2"
        :style="orderBy ? '' : 'fill-opacity: 0;'"
      />
      <span
        v-if="sortIndex"
        class="h-3 text-white bg-current absolute -top-1.5 flex-center"
        style="font-size: 0.6rem; width: 0.6rem; right: -0.175rem"
      >
        {{ sortIndex }}
      </span>
    </div>
  </div>
</template>
<script>
import { computed } from 'vue';
export default {
  name: 'SynSortIcon',
  components: {},
  props: {
    orderBy: {
      type: String,
      default: '',
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    sortIndex: {
      type: Number,
      default: null,
    },
  },
  emits: ['onSort'],
  setup(props) {
    const iconName = computed(() => {
      if (!props.orderBy) return 'sort-down';
      return props.orderBy == 'asc' ? 'sort-up' : 'sort-down';
    });
    return { iconName };
  },
};
</script>
