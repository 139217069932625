import { defineStore } from 'pinia';
import storeId from '@/store/storeId';
import DashboardService from '@/application/services/dashboard/DashboardService';
import { DATE_OPTIONS } from '@/store/dashboard/dateOptions-const';
import { translate } from '@/ui/plugins/i18n/myi18n';

export default defineStore({
  id: storeId.DASHBOARD,
  state: () => ({
    isLoading: {
      customerRegistered: false,
      customerStatus: false,
    },
    currentDistId: 0,
    chartData: [] as any[],
    referenceUserCounts: [] as any[],
    labels: [] as any[],
    referenceStatus: {} as any,
    dateOptions: DATE_OPTIONS,
    referenceUserOverview: [] as any[],
    maxReferenceUserCounts: 0,
    referenceStatusList: [] as any[],
    referenceListByDate: [] as any[],
    data: {
      period: -7,
      currentTimeZone: '+7',
    },
    dateStr: '',
  }),
  getters: {},
  actions: {
    // async initData() {
    //   this.isLoading = true;
    //
    //   this.currentDistId = parseInt(
    //     getLocalStorage(StorageConstant.DISTRIBUTOR_ID) || '0'
    //   );
    //   await Promise.all([this.getAllReferenceUsers(this.currentDistId)]);
    //   await Promise.all([this.getDistributorDetail(this.currentDistId)]);
    //
    //   this.isLoading = false;
    // },
    async getReferenceByDate() {
      try {
        this.isLoading.customerRegistered = true;
        const response =
          await DashboardService.getInstance().getReferenceByDate(this.data);

        if (response?.result) {
          this.chartData = response.result || [];
          this.labels = response.result.map((o) => o.date);
          this.referenceUserCounts = response.result.map(
            (o) => o.referenceUserCount
          );
          this.maxReferenceUserCounts = Math.max(...this.referenceUserCounts);
          this.referenceUserOverview = [
            {
              label: translate('COMMON_LABEL_CUSTOMERS_REGISTERED'),
              data: this.referenceUserCounts,
              backgroundColor: '#0891b2',
              hoverBackgroundColor: '#155e75',
              minBarLength: 1,
              categoryPercentage: 0.5,
            },
          ];
          this.isLoading.customerRegistered = false;
        } else {
          this.isLoading.customerRegistered = false;
        }
      } catch (error) {
        this.isLoading.customerRegistered = false;
        console.log('🚀 ~ getReferenceByDate ~ error', error);
      }
    },

    async countReferencePaymentStatus() {
      try {
        this.isLoading.customerStatus = true;
        const response =
          await DashboardService.getInstance().countReferencePaymentStatus();
        if (response?.result) {
          this.referenceStatus = response.result;
          this.referenceStatusList = [
            {
              data: response.result.freeUsers,
              color: '#7fc6c9',
              name: 'Free',
              label: 'Free',
            },
            {
              data: response.result.paidUsers,
              color: '#fdba74',
              name: 'Premium',
              label: 'Premium',
            },
          ];
          this.isLoading.customerStatus = false;
        } else {
          this.isLoading.customerStatus = false;
        }
        return response.result;
      } catch (error) {
        this.isLoading.customerStatus = false;
        console.log('🚀 ~ countReferencePaymentStatus ~ error', error);
      }
    },
    async getReferenceBySpecificDate(data) {
      const { currentTimeZone, dateStr } = data;
      const response =
        await DashboardService.getInstance().getReferenceBySpecificDate({
          currentTimeZone,
          dateStr,
        });
      if (response.result) {
        this.referenceListByDate = response.result;
      }
      return response.result;
    },
  },
});
