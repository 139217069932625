<template>
  <div :class="`${background} ${shadow} z-50 divide-y rounded-md`">
    <slot></slot>
  </div>
</template>
<script>
export default {
  props: {
    shadow: {
      type: String,
      default: '',
      required: false,
    },
    background: {
      type: String,
      default: '',
      required: false,
    },
  },
  setup() {},
};
</script>
