<template>
  <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      viewBox="0 0 128 128"
      style="enable-background: new 0 0 512 512"
      xml:space="preserve"
  >
                <g>
                  <g xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="m64 19a4.161 4.161 0 0 0 -4.16 4.16v47.458l-15.909-15.909a4.16 4.16 0 1 0 -5.883 5.884l23.01 23.007a4.162 4.162 0 0 0 5.884 0l23.01-23.011a4.16 4.16 0 1 0 -5.883-5.884l-15.909 15.913v-47.458a4.161 4.161 0 0 0 -4.16-4.16z"
                    ></path>
                    <path
                        d="m23.16 74.005a4.16 4.16 0 0 0 -4.16 4.16v13.708a17.146 17.146 0 0 0 17.127 17.127h55.746a17.146 17.146 0 0 0 17.127-17.127v-13.708a4.16 4.16 0 1 0 -8.32 0v13.708a8.817 8.817 0 0 1 -8.807 8.807h-55.746a8.817 8.817 0 0 1 -8.807-8.807v-13.708a4.159 4.159 0 0 0 -4.16-4.16z"
                    ></path>
                  </g>
                </g>
              </svg>
</template>
