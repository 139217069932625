import NProgress from 'nprogress';
import router from './router';
// import { StorageConstant, getLocalStorage } from '@/ui/hooks/storageHook';
import RouterInstanceClass from './router/RouterInstanceClass';
import { getLocalStorage, StorageConstant } from '@/ui/hooks/storageHook';

const whiteList = ['/login']; // no redirect whitelist

NProgress.configure({ showSpinner: false }); // NProgress Configuration

router.beforeEach(async (to, from, next) => {
  NProgress.start();

  if (whiteList.indexOf(to.path) >= 0) {
    next();
  }
  // const token = 'getLocalStorage(StorageConstant.TOKEN)';
  const token = getLocalStorage(StorageConstant.TOKEN);

  if (token) {
    const routerInstance = RouterInstanceClass.getInstance();
    const permissionCreated = routerInstance.permissionCreated;

    if (permissionCreated) next();

    await RouterInstanceClass.getInstance().generateRoutes();

    next({ ...to, replace: true });
  } else {
    // next(`/login?redirect=${to.fullPath.replace('?', '&')}`);
    next('/login');
  }
});

router.afterEach(() => {
  // finish progress bar
  NProgress.done();
});
