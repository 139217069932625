import clickOutSide from '@/ui/directive/clickOutSide';
import scrollInfinite from '@/ui/directive/scrollInfinite';
import vigTooltip from '@/ui/directive/vigTooltip';
import vigFocus from '@/ui/directive/vigFocus';
import perfectScrollbar from '@/ui/directive/perfect-scrollbar';
import vIntersectionObserver from '@/ui/directive/v-intersection-observer';
import vListenScroll from '@/ui/directive/v-listen-scroll';
import vDetectMobile from '@/ui/directive/v-detect-mobile';

export default function usingDirective(app) {
    app.use(clickOutSide);
    app.use(scrollInfinite);
    app.use(vigTooltip);
    app.use(vigFocus);
    app.use(perfectScrollbar);
    app.use(vIntersectionObserver);
    app.use(vListenScroll);
    app.use(vDetectMobile);
}
