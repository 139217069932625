// Dashboard
import OverviewDistributor from '@/ui/pages/overview/OverviewDistributor.vue';
import Members from '@/ui/pages/members/Members.vue';
import Settings from '@/ui/pages/settings/Settings.vue';
import Profile from '@/ui/pages/profile/Profile.vue';
import DistributorProfilePage from '@/ui/pages/distributor-profile/DistributorProfilePage.vue';

export default [
  {
    path: '/',
    name: 'Home',
    component: OverviewDistributor,
    redirect: { name: 'Dashboard' },
    meta: { layout: 'dashboard' },
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
    meta: { layout: 'dashboard' },
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: OverviewDistributor,
    meta: { layout: 'dashboard' },
  },
  {
    path: '/customers',
    name: 'Customers',
    component: Members,
    meta: { layout: 'dashboard' },
  },
  {
    path: '/distributor-profile',
    name: 'DistributorProfile',
    component: DistributorProfilePage,
    meta: { layout: 'dashboard' },
  },
  {
    path: '/settings',
    name: 'Settings',
    component: Settings,
    meta: { layout: 'dashboard' },
  },
];
