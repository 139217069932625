import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(duration);
dayjs.extend(utc);
dayjs.extend(relativeTime);

export default {
  install: (app) => {
    app.config.globalProperties.$filters = {
      currency(value, location) {
        let formatter;
        switch (location) {
          case 'en':
            formatter = new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
            });
            break;
          case 'fr':
            formatter = new Intl.NumberFormat('fr-FR', {
              style: 'currency',
              currency: 'EUR',
            });
            break;
          case 'vi':
            formatter = new Intl.NumberFormat('vi-VI', {
              style: 'currency',
              currency: 'VND',
            });
            break;

          default:
            formatter = new Intl.NumberFormat('fr-FR', {
              style: 'currency',
              currency: 'EUR',
            });
            break;
        }
        return formatter.format(value);
      },
      number(value, options) {
        return new Intl.NumberFormat(
          options ? options.local : 'en-IN',
          options || { maximumSignificantDigits: 3 }
        ).format(value);
      },
      dayjs(value, temp = 'DD/MM/YYYY', timezone = '0') {
        if (!value) return '';
        const zone = parseInt(timezone);
        const dateTimeWithZone = dayjs(value).add(zone, 'hour');
        return dayjs(dateTimeWithZone).format(temp);
      },
      dayjsUtc(value, temp = 'DD/MM/YYYY', timezone = 0) {
        if (!value) return '';
        const zone = parseInt(timezone.toString());
        const dateTimeWithZone = dayjs(value).add(zone, 'hour');
        return dayjs(dateTimeWithZone).utc().format(temp);
      },
      getDayOfWeek(value) {
        const intDay = dayjs(value).day();
        if (intDay == 0) return 'Sunday';
        if (intDay == 1) return 'Monday';
        if (intDay == 2) return 'Tuesday';
        if (intDay == 3) return 'Wednesday';
        if (intDay == 4) return 'Thursday';
        if (intDay == 5) return 'Friday';
        return 'Saturday';
      },
      dayjsByZone(value, timezone = '0', temp = 'HH:mm:ss') {
        const zone = parseInt(timezone);
        const currentUtcTime = dayjs.utc(value).add(zone, 'hour');
        return dayjs(currentUtcTime).utc(false).format(temp);
      },
      getCurrentUtcTime(temp = 'HH:mm:ss') {
        const currentUtcTime = dayjs.utc().valueOf();
        return dayjs(currentUtcTime).utc(false).format(temp);
      },
      durationTime(value, format = 'hh mm ss') {
        // value is miliseconds
        // Time calculations for days, hours, minutes and seconds
        const days = Math.floor(value / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (value % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor((value % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((value % (1000 * 60)) / 1000);

        // Output the result in an element with id="demo"
        let result = '';
        switch (format) {
          case 'HH MM SS':
            if (days > 0) result += `${days}d`;
            if (hours > 0) result += ` ${hours} hours`;
            if (minutes > 0) result += ` ${minutes} minutes`;
            if (seconds > 0) result += ` ${seconds} seconds`;
            break;
          case 'hh mm ss':
            if (days > 0) result += `${days}d`;
            if (hours > 0) result += ` ${hours}h`;
            if (minutes > 0) result += ` ${minutes}m`;
            if (seconds > 0) result += ` ${seconds}s`;
            break;
          case 'hh mm':
            if (hours > 0) result += ` ${hours}h`;
            if (minutes > 0) result += ` ${minutes}m`;
            break;

          default:
            result = `${days > 0 ? days + 'd ' : ''}${('0' + hours).slice(
              -2
            )}:${('0' + minutes).slice(-2)}:${('0' + seconds).slice(-2)}`;
            break;
        }
        return result || '< 1m';
      },
      duration(value, format = 'mm:ss') {
        return dayjs.duration(value).format(format);
      },
      fromNow(value) {
        return dayjs(value).fromNow(true);
      },
      fileSize(number) {
        if (number >= 1048576) {
          return (number / 1048576).toFixed(1) + ' MB';
        }
        if (number >= 1024 && number < 1048576) {
          return (number / 1024).toFixed(1) + ' KB';
        }
        return number + ' bytes';
      },
    };
    app.provide('dayjs', (value, temp = 'DD/MM/YYYY', timezone = '+7') => {
      const zone = parseInt(timezone);
      const dateTimeWithZone = dayjs(value).add(zone, 'hour');
      return dayjs(dateTimeWithZone).format(temp);
    });
    app.provide('fromNow', (value, suffix = true) => {
      return dayjs(value).fromNow(suffix);
    });
    app.provide('dayjsByZone', (value, timezone = '0') => {
      const zone = parseInt(timezone);
      const currentUtcTime = dayjs.utc(value).add(zone, 'hour');
      return dayjs(currentUtcTime).utc(false);
    });
    app.provide('getCurrentTimeByZone', (diff, timezone = '+7') => {
      const zone = parseInt(timezone);
      const now = new Date().getTime();
      return dayjs(now - diff + zone * 60 * 60000);
    });
    app.provide('getServerTime', (diff) => {
      const now = new Date().getTime();
      return now + diff;
    });
    app.provide('getUtcTime', (diff) => {
      const currenTime = new Date();
      return currenTime.getTime() + diff;
    });
    app.provide('getHour', (value, timezone = '+7') => {
      const zone = parseInt(timezone);
      const currentUtcTime = dayjs(value).add(zone, 'hour');
      return dayjs(currentUtcTime).hour();
    });
    app.provide('onFollowOASuccess', () => {
      console.log('Thank you for followed our Offical Account');
      //  Not work - check if it needed
    });
  },
};

export const getDiffDate = (createdDate) => {
  const dStringDate = dayjs.utc(createdDate).local().format('MM/DD/YYYY');
  const todayString = dayjs().format('MM/DD/YYYY');
  const yesterdayString = dayjs(dayjs().add(-1, 'd'))
    .local()
    .format('MM/DD/YYYY');

  if (dStringDate === todayString) {
    return 0;
  } else if (dStringDate === yesterdayString) {
    return 1;
  } else {
    return 2;
  }
};

export const diffDate = (
  date1,
  date2,
  type:
    | 'millisecond'
    | 'second'
    | 'minute'
    | 'hour'
    | 'day'
    | 'month'
    | 'year'
    | 'date' = 'millisecond',
  isFloat = false
) => {
  const _date1 = dayjs(date1);
  const _date2 = dayjs(date2);
  return _date1.diff(_date2, type, isFloat);
};

export const getUserCurrentDateTime = () => {
  return dayjs();
};
export const formatDate = (value, temp = 'DD/MM/YYYY', timezone = '0') => {
  if (!value) return '';
  const zone = parseInt(timezone);
  const dateTimeWithZone = dayjs(value).add(zone, 'hour');
  return dayjs(dateTimeWithZone).format(temp);
};
