<template>
  <div
    v-if="hasAction"
    :class="`rounded-full flex item-center cursor-pointer flex-center p-1 hover:bg-${color}-50`"
  >
    <component
      :is="name"
      class="inline-block"
      :is-active="isActive"
      :class="
        customClass ? customClass : `w-4 h-4 text-${color}-500 fill-${color}`
      "
    >
    </component>
  </div>
  <component
    :is="name"
    v-else
    class="inline-block"
    :class="customClass ? customClass : `w-4 h-4`"
    :is-active="isActive"
  >
  </component>
</template>
<script>
export default {
  name: 'SynIcon',
  components: {},
  props: {
    name: {
      type: String,
      required: true,
      default: 'coffee',
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    hasAction: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: '',
    },
    customClass: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: 'current',
    },
  },
  setup: () => {
    return {};
  },
};
</script>
