import { filterRoutes } from '@/ui/router';

export default class RouterInstanceClass {
  private static instance: RouterInstanceClass;

  permissionCreated: boolean;
  dynamicRouteCreated: boolean;
  constructor() {
    this.permissionCreated = false;
    this.dynamicRouteCreated = false;
  }

  public static getInstance(): RouterInstanceClass {
    if (!RouterInstanceClass.instance) {
      // Get from local storage
      RouterInstanceClass.instance = new RouterInstanceClass();
    }

    return RouterInstanceClass.instance;
  }

  async generateRoutes() {
    await filterRoutes();
    this.permissionCreated = true;
    return true
  }
}
