import NotFound from '../pages/notfound/404.vue';

export default [
  {
    // path: '/:catchAll(.*)',
    path: '/:pathMatch(.*)*',
    name: 'Notfound',
    component: NotFound,
    meta: { layout: 'Empty' },
  },
];
