<template>
  <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 98 28"
      fill="none"
  >
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M27.1785 9.95555C27.7634 9.95555 28.2487 10.1547 28.6345 10.5404C29.0203 10.9387 29.207 11.4116 29.207 11.9716V21.504C29.207 22.064 29.0203 22.5493 28.6345 22.9351C28.2487 23.3209 27.7634 23.52 27.1785 23.52C26.3199 23.52 25.6852 23.1093 25.287 22.2756C24.4407 23.2711 23.271 23.7689 21.8025 23.7689C20.0354 23.7689 18.5545 23.0969 17.3474 21.728C16.1403 20.3591 15.543 18.704 15.543 16.7378C15.543 14.7716 16.1403 13.1164 17.3474 11.7476C18.5421 10.3911 20.0354 9.70667 21.8025 9.70667C23.271 9.70667 24.4407 10.2044 25.287 11.2C25.6852 10.3662 26.3074 9.95555 27.1785 9.95555ZM22.375 20.0604C23.2461 20.0604 23.9181 19.7369 24.4159 19.1022C24.9012 18.4675 25.1501 17.6835 25.1625 16.7378C25.1625 15.792 24.9136 15.008 24.4159 14.3733C23.9181 13.7387 23.2461 13.4151 22.375 13.4151C21.5039 13.4151 20.8319 13.7387 20.3341 14.3733C19.8363 15.008 19.5874 15.8044 19.5874 16.7378C19.5874 17.6711 19.8363 18.4675 20.3341 19.1022C20.8319 19.7369 21.5039 20.0604 22.375 20.0604Z"
        fill="#0068FF"
    />
    <path
        d="M33.7989 22.9351C33.4131 23.3209 32.9278 23.52 32.3429 23.52C31.758 23.52 31.2727 23.3209 30.8869 22.9351C30.5011 22.5493 30.3145 22.064 30.3145 21.504V4.45509C30.3145 3.89509 30.5011 3.40975 30.8869 3.02398C31.2727 2.6382 31.758 2.43909 32.3429 2.43909C32.9278 2.43909 33.4131 2.6382 33.7989 3.02398C34.1847 3.40975 34.3713 3.89509 34.3713 4.45509V21.5164C34.3713 22.0764 34.1722 22.5493 33.7989 22.9351Z"
        fill="#0068FF"
    />
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M42.2624 9.70667C44.2784 9.70667 45.9335 10.3787 47.2277 11.7476C48.4846 13.0791 49.1068 14.7467 49.0944 16.7378C49.0944 18.7289 48.4722 20.3964 47.2277 21.728C45.9335 23.0844 44.2784 23.7689 42.2624 23.7689C40.2464 23.7689 38.6037 23.0969 37.3344 21.7529C36.0526 20.4089 35.418 18.7289 35.418 16.7378C35.418 14.7467 36.0651 13.0667 37.3344 11.7227C38.6162 10.3787 40.2464 9.70667 42.2624 9.70667ZM42.2624 20.0604C43.1335 20.0604 43.8055 19.7369 44.3033 19.1022C44.8011 18.4675 45.0499 17.6835 45.0375 16.7378C45.0375 15.792 44.8011 15.008 44.3033 14.3733C43.8055 13.7387 43.1335 13.4151 42.2624 13.4151C41.3913 13.4151 40.7193 13.7387 40.2215 14.3733C39.7237 15.008 39.4748 15.8044 39.4748 16.7378C39.4748 17.6711 39.7237 18.4675 40.2215 19.1022C40.7193 19.7369 41.3913 20.0604 42.2624 20.0604Z"
        fill="#0068FF"
    />
    <path
        d="M13.2909 10.1795C14.722 8.32529 15.4438 7.08085 15.4438 6.44618C15.4438 4.96529 14.5105 4.21863 12.6438 4.21863H2.80028C2.02872 4.21863 1.43139 4.40529 1.00828 4.76618C0.585166 5.12707 0.373611 5.61241 0.373611 6.19729C0.373611 6.78218 0.585166 7.25507 1.00828 7.62841C1.43139 7.98929 2.02872 8.17596 2.80028 8.17596H9.55761L0.908722 19.3635C0.348722 20.0977 0.0625 20.7449 0.0625 21.3048C0.0625 22.9351 1.13272 23.744 3.28561 23.744H13.4403C15.058 23.744 15.8669 23.0595 15.8669 21.7031C15.8669 20.3466 15.058 19.6622 13.4403 19.6622H5.97361L13.2909 10.1795Z"
        fill="#0068FF"
    />
    <path
        d="M62.1093 8.12622H59.3965V13.776H62.1093C62.9307 13.776 63.5902 13.5147 64.088 12.9796C64.5982 12.4444 64.8471 11.7724 64.8471 10.9511C64.8471 10.1298 64.5982 9.45778 64.088 8.92267C63.5902 8.4 62.9182 8.12622 62.1093 8.12622Z"
        fill="#03CA77"
    />
    <path
        d="M75.663 13.4399C74.8044 13.4399 74.1199 13.7511 73.6346 14.3857C73.1493 15.0204 72.9004 15.8044 72.9004 16.7377C72.9004 17.6711 73.1493 18.4551 73.6346 19.0897C74.1199 19.7244 74.8044 20.0355 75.663 20.0355C76.5217 20.0355 77.2061 19.7244 77.6915 19.0897C78.1768 18.4551 78.4257 17.6711 78.4257 16.7377C78.4257 15.8044 78.1768 15.0204 77.6915 14.3857C77.2061 13.7511 76.5217 13.4399 75.663 13.4399Z"
        fill="#03CA77"
    />
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M94.8514 0.0621948H55.4648C54.1457 0.0621948 53.0879 1.13242 53.0879 2.43908V25.5111C53.0879 26.8302 54.1581 27.888 55.4648 27.888H86.551C86.2274 27.5769 86.0532 27.216 86.0532 26.7929C86.0532 26.4569 86.1279 26.096 86.2772 25.7226L87.7457 22.4622L83.6888 12.3324C83.5643 12.0462 83.5146 11.7351 83.5146 11.3866C83.5146 10.9386 83.7012 10.5653 84.0746 10.2666C84.4479 9.95553 84.8337 9.8062 85.2443 9.8062C86.1403 9.8062 86.7626 10.2418 87.0861 11.1129L89.6123 18.0195L92.3128 11.1129C92.6488 10.2418 93.2586 9.8062 94.1546 9.8062C94.5652 9.8062 94.951 9.95553 95.3243 10.2666C95.6977 10.5778 95.8843 10.9511 95.8843 11.3866C95.8843 11.7226 95.8221 12.0462 95.7101 12.3324L89.6994 26.9173C89.5377 27.328 89.3386 27.6515 89.1021 27.8755H94.839C96.1581 27.8755 97.2159 26.8053 97.2159 25.4986V2.43908C97.2283 1.11997 96.1706 0.0621948 94.8514 0.0621948ZM67.0381 15.5929C65.7937 16.7253 64.2381 17.2977 62.3466 17.2977H59.3848V21.6533C59.3848 22.3129 59.1981 22.8231 58.8248 23.184C58.4515 23.5449 57.9786 23.7315 57.3812 23.7315C56.7964 23.7315 56.311 23.5449 55.9377 23.184C55.5644 22.8231 55.3777 22.3129 55.3777 21.6533V6.69508C55.3777 5.31374 56.0746 4.61686 57.4559 4.61686H62.1723C64.1386 4.61686 65.7688 5.20174 67.063 6.38397C68.3448 7.56619 68.9919 9.09686 68.9919 10.976C69.0043 12.88 68.3448 14.4106 67.0381 15.5929ZM81.8843 22.9102C82.2701 22.512 82.4568 22.0391 82.4568 21.4791V12.0213C82.4568 11.4489 82.2577 10.976 81.8843 10.5902C81.511 10.2044 81.0257 10.0053 80.4408 10.0053C79.5697 10.0053 78.9474 10.4284 78.5492 11.2498C77.703 10.2542 76.5581 9.75642 75.0897 9.75642C73.335 9.75642 71.8541 10.4284 70.6594 11.7849C69.4648 13.1413 68.8674 14.7964 68.8674 16.7502C68.8674 18.704 69.4648 20.3591 70.6594 21.7155C71.8541 23.072 73.335 23.744 75.0897 23.744C76.5457 23.744 77.703 23.2462 78.5492 22.2506C78.9474 23.0844 79.5821 23.4951 80.4408 23.4951C81.0257 23.4951 81.4985 23.296 81.8843 22.9102Z"
        fill="#03CA77"
    />
  </svg>
</template>
