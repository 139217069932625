<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:svgjs="http://svgjs.com/svgjs"
    width="512"
    height="512"
    x="0"
    y="0"
    viewBox="0 0 511.9 511.9"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
    class=""
  >
    <g>
      <path
        xmlns="http://www.w3.org/2000/svg"
        style=""
        d="M473.7,88.25H38.3c-21.1,0-38.3,17.1-38.3,38.3v258.8c0,21.2,17.2,38.3,38.3,38.3h435.3  c21.2,0,38.3-17.2,38.3-38.3v-258.8C512,105.35,494.8,88.25,473.7,88.25z"
        fill="#ff4b55"
        data-original="#ff4b55"
        class=""
      ></path>
      <path
        xmlns="http://www.w3.org/2000/svg"
        style=""
        d="M260.1,155.95l23.6,70.8l74.6,0.6c4.2,0,5.9,5.4,2.5,7.8l-60,44.3l22.5,71.1c1.3,4-3.3,7.3-6.7,4.8  l-60.6-43.3l-60.7,43.4c-3.4,2.4-7.9-0.9-6.7-4.8l22.5-71.1l-60-44.3c-3.4-2.5-1.6-7.8,2.5-7.8l74.6-0.6l23.6-70.8  C253.2,151.95,258.8,151.95,260.1,155.95z"
        fill="#ffe15a"
        data-original="#ffe15a"
        class=""
      ></path>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
      <g xmlns="http://www.w3.org/2000/svg"></g>
    </g>
  </svg>
</template>
