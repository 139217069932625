import UserRepository from '@/application/repositories/UserRepository';

export default class UserService {
  private static instance: UserService;
  _userRepository: UserRepository;

  constructor() {
    this._userRepository = UserRepository.getInstance();
  }

  public static getInstance(): UserService {
    if (!UserService.instance) {
      // Get from local storage
      UserService.instance = new UserService();
    }

    return UserService.instance;
  }

  genPass(data) {
    return this._userRepository.genPass(data);
  }

  getCurrentUser() {
    return this._userRepository.getCurrentUser();
  }

  updateUserProfile(data) {
    return this._userRepository.updateUserProfile(data);
  }

  updateUserAvatar(data) {
    return this._userRepository.updateUserAvatar(data);
  }

  updateUserPassword(data) {
    return this._userRepository.updateUserPassword(data);
  }
}
