<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    data-name="Layer 1"
    viewBox="0 0 185.93 59"
  >
    <path
      class="cls-1"
      d="M41.68,19.22l-5.61,5.61-.28.27L34,26.89l-1.33,1.33L30.9,30l-.28.28-.77.77-.28.28h0L26.94,34h0l-.28.28a11.54,11.54,0,0,1-4,2.61,11.38,11.38,0,0,1-3.39.74,10.82,10.82,0,0,1-2.13-.06,11.45,11.45,0,0,1-4.39-1.46,8,8,0,0,1-1.68-1.18h0l-.32-.33L.51,24.35l-.22-.22a3.63,3.63,0,0,1-.47-.64,3.75,3.75,0,0,1-.54-2c0-.11,0-.23,0-.34a4,4,0,0,1,.08-.48c0-.06,0-.12,0-.18l0-.08a4,4,0,0,1,.71-1.34l.28-.3.18-.18L11.48,7.74h0l6.59-6.59A3.94,3.94,0,0,1,23.43,1Z"
    />
    <path
      class="cls-2"
      d="M21.82,43.72a6.13,6.13,0,0,1-2.57-.58l-2.09-2.08-.51-.51-.09-.09-1-1-4.87-4.93.32.33a7.78,7.78,0,0,0,1.69,1.18,11.45,11.45,0,0,0,4.39,1.46,10.82,10.82,0,0,0,2.13.06,11.38,11.38,0,0,0,3.39-.74,11.54,11.54,0,0,0,4-2.61l.28-.28h0l2.63-2.63h0l.28-.28.77-.77L30.9,30l1.78-1.78L34,26.89l1.78-1.79.28-.27,11.3-11.3,4.66-5,0,0,.25-.25a3.66,3.66,0,0,1,5.16,0L60,10.75h0l.09.09A4.19,4.19,0,0,0,57,12l-8.63,8.5-.09.1-15,14.79,0,0-5.53,5.44,0,.05s-.13.15-.33.34A9.85,9.85,0,0,1,24.74,43a7.14,7.14,0,0,1-1.67.56,6.85,6.85,0,0,1-1,.13Z"
    />
    <path
      class="cls-2"
      d="M73.84,29.74l-.27.27L61.33,42.44,48.47,55.49a11.15,11.15,0,0,1-1.29,1.11l0,0-.19.13,0,0a11.86,11.86,0,0,1-15-1.06h0l-.29-.25-2.21-2.22-6.21-6.21L21.08,45l-.29-.29a7,7,0,0,0,1,.08h.29a6.67,6.67,0,0,0,1.18-.14A8.76,8.76,0,0,0,25.19,44,11,11,0,0,0,28,42a5.52,5.52,0,0,0,.39-.41l4.78-4.69,0,0L49.65,20.73l.09-.11,8-7.85a3.16,3.16,0,0,1,4-.35l1.89,1.9h0L73.84,24.58a3.45,3.45,0,0,1,.26.28A3.66,3.66,0,0,1,73.84,29.74Z"
    />
    <path
      class="cls-3"
      d="M34.2,16.13a.75.75,0,0,1-1.06,0A12.46,12.46,0,1,0,15.45,33.68l3.79,3.82a.47.47,0,0,1,.06.09h-.05a11.13,11.13,0,0,1-2.08-.05l-2.78-2.81A14,14,0,0,1,34.2,15.07h0A.74.74,0,0,1,34.2,16.13Z"
    />
    <path
      class="cls-3"
      d="M40,20.91,38.94,22,37.09,20.1a.74.74,0,0,1,0-1.05.73.73,0,0,1,1,0Z"
    />
    <path
      class="cls-3"
      d="M24.11,36.16l0,0a12.73,12.73,0,0,1-1.4.66l-5.16-5.21a9.55,9.55,0,0,1-1.1-12.13.74.74,0,1,1,1.22.84,8.07,8.07,0,0,0,.93,10.24Z"
    />
    <path
      class="cls-3"
      d="M36.87,24l0,0-.78.78-.25.24L30,19.22a8,8,0,0,0-8.21-2,.74.74,0,1,1-.46-1.41,9.51,9.51,0,0,1,9.73,2.35Z"
    />
    <path
      class="cls-3"
      d="M33.76,27.14l-1,1.05-5.82-5.86a3.66,3.66,0,1,0-5.19,5.15l3.75,3.78a.73.73,0,0,1,0,1.05.74.74,0,0,1-1.05,0l-3.75-3.78a5.14,5.14,0,1,1,7.3-7.25Z"
    />
    <path
      class="cls-3"
      d="M30.65,30.25l0,0-.77.77-.25.25-5.84-5.87a.75.75,0,0,1,0-1.06.74.74,0,0,1,1.05,0Z"
    />
    <path
      class="cls-3"
      d="M102.18,47.85a2.16,2.16,0,0,0,1.24-.29,1.42,1.42,0,0,0,.54-.89.84.84,0,0,0-.09-.63,2.67,2.67,0,0,0-.72-.63l-1.49-1a3.2,3.2,0,0,1-.77-.73,1.7,1.7,0,0,1-.31-.77,2.7,2.7,0,0,1,.06-.88,2.9,2.9,0,0,1,1.12-1.8,4.22,4.22,0,0,1,2.54-.66,5,5,0,0,1,1.24.16,3.32,3.32,0,0,1,.88.35.83.83,0,0,1,.19.15.26.26,0,0,1,0,.2l-.19.88c0,.21-.17.27-.37.19a5.71,5.71,0,0,0-1-.26A6.15,6.15,0,0,0,104,41.1c-.91,0-1.43.3-1.55.9a.57.57,0,0,0,.1.53,3.57,3.57,0,0,0,.71.6l1.36.92a3.65,3.65,0,0,1,.9.81,1.91,1.91,0,0,1,.35.84,2.73,2.73,0,0,1,0,1A3.26,3.26,0,0,1,103.92,49a4.89,4.89,0,0,1-2,.38,5.15,5.15,0,0,1-1.42-.18,3.45,3.45,0,0,1-.85-.34.78.78,0,0,1-.2-.16.25.25,0,0,1,0-.18l.19-.88a.25.25,0,0,1,.37-.2A6.84,6.84,0,0,0,102.18,47.85Z"
    />
    <path
      class="cls-3"
      d="M109.54,47.93a6.49,6.49,0,0,0,1.73-.29c.2,0,.27,0,.23.21l-.17.82a.43.43,0,0,1-.11.2A.84.84,0,0,1,111,49a3.81,3.81,0,0,1-1.74.37,3.06,3.06,0,0,1-1.46-.32,1.76,1.76,0,0,1-.83-1,3.58,3.58,0,0,1,0-1.79l.26-1.21a4.49,4.49,0,0,1,.75-1.76,3.19,3.19,0,0,1,1.27-1.05,3.85,3.85,0,0,1,1.6-.33,2.92,2.92,0,0,1,1.59.37.47.47,0,0,1,.18.14.26.26,0,0,1,0,.2l-.18.82c0,.19-.14.26-.31.21l-.73-.22a3.75,3.75,0,0,0-.81-.07c-.88,0-1.43.56-1.67,1.69l-.26,1.21a1.71,1.71,0,0,0,.08,1.3A.91.91,0,0,0,109.54,47.93Z"
    />
    <path
      class="cls-3"
      d="M116.3,43.31a7.16,7.16,0,0,0-2.13.34c-.2.05-.27,0-.23-.21l.16-.79a.36.36,0,0,1,.11-.2.66.66,0,0,1,.24-.14,7.41,7.41,0,0,1,1-.3,6.86,6.86,0,0,1,1.31-.12,3,3,0,0,1,1.37.27,1.47,1.47,0,0,1,.74.83,2.51,2.51,0,0,1,0,1.37L118,48.77a.38.38,0,0,1-.07.2.27.27,0,0,1-.17.09,8.88,8.88,0,0,1-1.16.24,10.15,10.15,0,0,1-1.34.08,5.16,5.16,0,0,1-1.69-.22,1.39,1.39,0,0,1-.85-.7,2,2,0,0,1-.06-1.25l0-.07a3.23,3.23,0,0,1,.37-1,2.17,2.17,0,0,1,.67-.68,3,3,0,0,1,1-.41,6.32,6.32,0,0,1,1.47-.14h.91l.12-.57c.08-.4,0-.68-.1-.83A1.16,1.16,0,0,0,116.3,43.31Zm.16,4.67.4-1.91H116a2.9,2.9,0,0,0-.89.1.83.83,0,0,0-.44.32,1.84,1.84,0,0,0-.22.62l0,.07a1,1,0,0,0,0,.56.46.46,0,0,0,.34.27,2.68,2.68,0,0,0,.8.08A3.23,3.23,0,0,0,116.46,48Z"
    />
    <path
      class="cls-3"
      d="M125.38,49a.31.31,0,0,1-.12.19.32.32,0,0,1-.2.08h-1.19a.18.18,0,0,1-.16-.08.26.26,0,0,1,0-.19l.91-4.28a2.43,2.43,0,0,0,.06-.81.62.62,0,0,0-.31-.45,1.65,1.65,0,0,0-.75-.13,7,7,0,0,0-1.3.13L121.1,49a.31.31,0,0,1-.11.19.32.32,0,0,1-.2.08H119.6a.2.2,0,0,1-.17-.08.25.25,0,0,1,0-.19l1.38-6.48c0-.12.13-.21.31-.26a12.42,12.42,0,0,1,1.45-.28,10.34,10.34,0,0,1,1.4-.1,2.65,2.65,0,0,1,2.1.67,2.53,2.53,0,0,1,.26,2.16Z"
    />
    <path
      class="cls-3"
      d="M134.28,49.06a.29.29,0,0,1-.29.23,6.65,6.65,0,0,1-1,.09,3.59,3.59,0,0,1-1.18-.15.84.84,0,0,1-.51-.6,2.86,2.86,0,0,1,.07-1.3l.86-4.07-1-.18c-.17,0-.24-.12-.21-.26l.12-.59a.34.34,0,0,1,.11-.18.31.31,0,0,1,.2-.07h1.08l.23-1.07a.33.33,0,0,1,.31-.26l1.25-.21h.06c.13,0,.18.07.15.2L134.18,42h1.31a.24.24,0,0,1,.18.06.29.29,0,0,1,0,.19l-.17.77a.29.29,0,0,1-.11.18.34.34,0,0,1-.2.08h-1.31l-.86,4.07a1.24,1.24,0,0,0-.05.6c0,.08.17.12.39.12h.83c.18,0,.26.06.24.19Z"
    />
    <path
      class="cls-3"
      d="M141.84,46.16a4.4,4.4,0,0,1-.71,1.66A3.56,3.56,0,0,1,139.84,49a3.71,3.71,0,0,1-1.78.41,2.76,2.76,0,0,1-1.59-.41,1.9,1.9,0,0,1-.82-1.15,3.57,3.57,0,0,1,0-1.66l.22-1a4.57,4.57,0,0,1,.71-1.66,3.54,3.54,0,0,1,1.31-1.15,3.67,3.67,0,0,1,1.77-.41,2.77,2.77,0,0,1,1.6.41,2,2,0,0,1,.8,1.15,3.43,3.43,0,0,1,0,1.66Zm-1.7,0,.22-1a1.89,1.89,0,0,0-.09-1.28,1,1,0,0,0-.93-.47,1.53,1.53,0,0,0-1.13.47,2.66,2.66,0,0,0-.63,1.28l-.22,1a1.89,1.89,0,0,0,.09,1.28,1,1,0,0,0,.92.47,1.52,1.52,0,0,0,1.13-.47A2.59,2.59,0,0,0,140.14,46.16Z"
    />
    <path
      class="cls-3"
      d="M153.87,42.66A3.35,3.35,0,0,1,152.35,45a5.44,5.44,0,0,1-2.86.71h-.44l-.45,0-.25,0L147.64,49a.4.4,0,0,1-.11.19.3.3,0,0,1-.19.07H146a.2.2,0,0,1-.16-.07.19.19,0,0,1,0-.19l1.89-8.91a.41.41,0,0,1,.16-.27.71.71,0,0,1,.34-.12,17.45,17.45,0,0,1,2.57-.17,4.06,4.06,0,0,1,2.55.7,2.13,2.13,0,0,1,.54,2.29Zm-1.79,0,0-.12a1.12,1.12,0,0,0-.22-1.15,2.13,2.13,0,0,0-1.39-.35,10.63,10.63,0,0,0-1.17.06l-.66,3.08,1.15,0a3.43,3.43,0,0,0,1.15-.16,1.56,1.56,0,0,0,.73-.51A2.16,2.16,0,0,0,152.08,42.66Z"
    />
    <path
      class="cls-3"
      d="M156.69,43.31a7.27,7.27,0,0,0-2.14.34c-.19.05-.27,0-.23-.21l.17-.79a.42.42,0,0,1,.1-.2.82.82,0,0,1,.25-.14,7,7,0,0,1,1-.3,6.86,6.86,0,0,1,1.31-.12,3,3,0,0,1,1.37.27,1.42,1.42,0,0,1,.73.83,2.41,2.41,0,0,1,0,1.37l-.94,4.41a.5.5,0,0,1-.07.2.3.3,0,0,1-.17.09,9.23,9.23,0,0,1-1.16.24,10.31,10.31,0,0,1-1.35.08,5.1,5.1,0,0,1-1.68-.22,1.39,1.39,0,0,1-.85-.7,2,2,0,0,1-.06-1.25v-.07a3.23,3.23,0,0,1,.38-1,2.14,2.14,0,0,1,.66-.68,3,3,0,0,1,1-.41,6.23,6.23,0,0,1,1.46-.14h.91l.12-.57c.09-.4.06-.68-.09-.83A1.16,1.16,0,0,0,156.69,43.31Zm.15,4.67.41-1.91h-.91a3,3,0,0,0-.9.1.87.87,0,0,0-.44.32,1.86,1.86,0,0,0-.21.62l0,.07a1,1,0,0,0,0,.56.46.46,0,0,0,.33.27,2.75,2.75,0,0,0,.81.08A3.16,3.16,0,0,0,156.84,48Z"
    />
    <path
      class="cls-3"
      d="M167.4,42.2a.24.24,0,0,1,0,.07l-3.78,7.57a6.35,6.35,0,0,1-.74,1.19,2.32,2.32,0,0,1-.92.64,4.22,4.22,0,0,1-1.41.2,4.67,4.67,0,0,1-.72,0,3.11,3.11,0,0,1-.61-.12c-.17,0-.25-.12-.23-.22l.18-.83a.28.28,0,0,1,.11-.16.31.31,0,0,1,.2-.07h0l.72,0c.36,0,.6,0,.73,0a1.45,1.45,0,0,0,.49-.07.9.9,0,0,0,.38-.25,2.25,2.25,0,0,0,.33-.51l.18-.39h-.19a1.1,1.1,0,0,1-.41-.07.63.63,0,0,1-.32-.31,1.61,1.61,0,0,1-.17-.69l-.43-5.94a.14.14,0,0,1,0-.09c0-.14.13-.2.29-.2h1.31a.2.2,0,0,1,.15.06.34.34,0,0,1,.05.17l.19,5.27c0,.19,0,.28.11.28H163l2.58-5.56a.33.33,0,0,1,.31-.22h1.33a.2.2,0,0,1,.14.06A.19.19,0,0,1,167.4,42.2Z"
    />
    <path
      class="cls-4"
      d="M154.75,27.24,148,17.53l-.69-.63H154l4.53,7.34.36-.3,2.74-4.06a2.14,2.14,0,0,0-.38-3h6.31l-7.29,10.34v7.5l1.29,1.53h-7.84l1.1-1.51c0-2.5-.09-5,0-7.52ZM136.36,16.9a2.8,2.8,0,0,1,.76,1.66,2.36,2.36,0,0,1-.14,1.15l-5,14.61a5.37,5.37,0,0,1-1.43,1.91H136a2.24,2.24,0,0,1-.76-1.31,2,2,0,0,1,0-1.09l1.11-3.47h6.4l1,2.67a3.41,3.41,0,0,1,.19.84,2.5,2.5,0,0,1-1,2.4h8.62a7.85,7.85,0,0,1-2.06-2.5L143.21,16.9Zm5.48,10.94h-4.57l2.22-6.32,2.35,6.32ZM118.93,16.91h9.59a5.27,5.27,0,0,1,5.26,5.25v.65A5.28,5.28,0,0,1,128.52,28l-2.4.07a4.92,4.92,0,0,0,2.5-5.14c-.2-1.62-1-3.18-2.42-3.21l-.88,0V34.11a1,1,0,0,0,.2.64,10.77,10.77,0,0,0,.87,1.18l.26.3h-7.46c.11-.14.22-.27.32-.42a4.5,4.5,0,0,0,.58-1,1.46,1.46,0,0,0,.14-.65V18.67C120.2,17.87,119.4,17.41,118.93,16.91Z"
    />
    <path
      class="cls-5"
      d="M78.49,16.81h6L89.57,31.3l4.28-12a1.38,1.38,0,0,0-.17-1c-.23-.49-.49-1-.72-1.45h4.49c-.26,1-.66,2.06-1,3l-5.8,16.88a9.42,9.42,0,0,1-4.72-2.24,8.1,8.1,0,0,1-1.9-3.4L79.17,18.25a11.86,11.86,0,0,0-.68-1.44Zm24,6.79V34c0,.42.1.5.36.84l1.18,1.54H98.41c.32-.48.63-1,1-1.46a1.52,1.52,0,0,0,.36-1V19.4l-1.6-2.59H104l9.51,12.25V19.3a1.59,1.59,0,0,0-.34-1c-.32-.49-.65-1-1-1.45h5.66l-1.18,1.55c-.26.33-.36.41-.36.83V36.83a8,8,0,0,1-5.92-3.08Z"
    />
    <path
      class="cls-5"
      d="M167.08,21.58a4.14,4.14,0,0,1,.18-1.22,4.7,4.7,0,0,1,.51-1.15,4.46,4.46,0,0,1,1.8-1.69,5.74,5.74,0,0,1,2.74-.62,4.2,4.2,0,0,1,1.2.15,4.38,4.38,0,0,1,1.1.48,4.15,4.15,0,0,1,1.63,1.69,5,5,0,0,1,.64,2.54,5.37,5.37,0,0,1-.44,2,4.87,4.87,0,0,1-1.25,1.77l.68.65a7.45,7.45,0,0,0,.86.72,4.75,4.75,0,0,0,1,.58,3.18,3.18,0,0,0,1.16.31,4.06,4.06,0,0,1-1.12.67,3.3,3.3,0,0,1-1.24.24,2.77,2.77,0,0,1-1.1-.24,5.5,5.5,0,0,1-1.07-.57c-.35-.23-.66-.48-1-.71l-.76-.64h-.2l-.31,0-.45,0a4.33,4.33,0,0,1-2.36-.68,4.58,4.58,0,0,1-1.66-1.85,5.54,5.54,0,0,1-.44-1.22,5.94,5.94,0,0,1-.16-1.35Zm2.69,0a4.33,4.33,0,0,0,.1.93c.06.22.14.51.26.85a3.22,3.22,0,0,0,.81,1.34,1.78,1.78,0,0,0,1.31.52,1.6,1.6,0,0,0,1.48-.79,3.9,3.9,0,0,0,.49-2c0-.21,0-.55-.07-1a8,8,0,0,0-.19-.95,4.72,4.72,0,0,0-.28-.78,2.19,2.19,0,0,0-.81-1,2.09,2.09,0,0,0-1.2-.35,1.53,1.53,0,0,0-.6.12,2,2,0,0,0-.5.33,2.36,2.36,0,0,0-.61,1,4.63,4.63,0,0,0-.19,1.37v.45Zm10.64.1a2,2,0,0,0,.79-.31,1.65,1.65,0,0,0,.54-.63,2.5,2.5,0,0,0,.15-.38,1.84,1.84,0,0,0,.05-.41,1.69,1.69,0,0,0-.35-1.08,1.3,1.3,0,0,0-1.07-.43h-.11Zm0,.75v2.63a1.39,1.39,0,0,0,.61,1.33v.06H177.2v-.06a1.39,1.39,0,0,0,.58-1.31V18.67a3.64,3.64,0,0,0-.1-.93,1.26,1.26,0,0,0-.48-.59v0h4.63a3.12,3.12,0,0,1,1.39.29,2.25,2.25,0,0,1,1,.85,2.22,2.22,0,0,1,.27.63,2.55,2.55,0,0,1,.1.74,2.26,2.26,0,0,1-.35,1.14,2.79,2.79,0,0,1-1.31,1.1l1.83,3.1a6.3,6.3,0,0,0,1.19,1.43l-1.61.12h-.78a1.75,1.75,0,0,1-.86-.18,1.66,1.66,0,0,1-.58-.71Z"
    />
  </svg>
</template>
<style>
.cls-1,
.cls-4 {
  fill: #005baa;
}
.cls-2,
.cls-5 {
  fill: #ed1c24;
}
.cls-3 {
  fill: #009edb;
}
.cls-4,
.cls-5 {
  fill-rule: evenodd;
}
</style>
