import { AxiosInstance, AxiosRequestConfig } from 'axios';
import BaseRequest from '@/base/api/base-request';
import BaseUnauthorizeRequest from '@/base/api/base-unauthorize-request';
import {getLocalStorage, setLocalStorage, StorageConstant} from "@/ui/hooks/storageHook";
import jwt_decode from "jwt-decode";
import router from "@/ui/router";

export interface IResponseConfig {
  message: string;
}

export class BaseResponse {
  success: boolean;
  result: any;
  error: any;
  targetUrl: string;
  unAuthorizedRequest: boolean;

  constructor(data) {
    this.success = data?.success || false;
    this.result = data?.result;
    this.error = data?.error || false;
    this.targetUrl = data?.targetUrl;
    this.unAuthorizedRequest = data?.unAuthorizedRequest;
  }
}
export default class BaseRepository {
  private static instance: BaseRepository;
  unAuthRequest: AxiosInstance;
  request: AxiosInstance;
  constructor() {
    this.request = BaseRequest;
    this.unAuthRequest = BaseUnauthorizeRequest;
  }
  public static getInstance(): BaseRepository {
    if (!BaseRepository.instance) {
      // Get from local storage
      BaseRepository.instance = new BaseRepository();
    }

    return BaseRepository.instance;
  }

  async requestWithAuthorize(
    requestConfig: AxiosRequestConfig
  ): Promise<BaseResponse> {
    try {
      await this.canActive();
      
      const res = await this.request(requestConfig);

      return Promise.resolve(new BaseResponse(res));
    } catch (error: any) {
      return Promise.reject(
        new BaseResponse({
          error: error?.error || true,
        })
      );
    }
  }
  
  async canActive(): Promise<void> {
    const active = await this.checkAndCallRefreshToken();
    if (!active) {
      router.push({
        name: 'Login',
      });
    }
  }

  async checkAndCallRefreshToken(): Promise<boolean> {
    try {
      const jwtToken = getLocalStorage(StorageConstant.TOKEN);
      const refreshToken = getLocalStorage(StorageConstant.REFRESH_TOKEN);

      const tokenDecoded = this.tokenDecoded(jwtToken);
      if (!tokenDecoded || tokenDecoded.exp < 0) return false;

      const currentTimeSec = Math.ceil(new Date().getTime() / 1000);
      if (tokenDecoded.exp > currentTimeSec) return true;

      const refreshRes = await this.unAuthRefreshToken({
        jwtToken: jwtToken,
        refreshToken: refreshToken
      })
      
      if (refreshRes?.result) {
        const { jwtToken, refreshToken } = refreshRes.result;
        setLocalStorage(StorageConstant.TOKEN, jwtToken);
        setLocalStorage(StorageConstant.REFRESH_TOKEN, refreshToken);
        return true;
      }

      return false;
    } catch (e) {
      return false;
    }
  }
  
  tokenDecoded = (token: string) => {
    if (!token || token.length < 20) return null;
    
    const decoded = jwt_decode(token) as any;

    return decoded;
  }

  unAuthRefreshToken(data): Promise<any> {
    return this.unAuthRequest({
      url: 'refresh',
      method: 'post',
      data,
    });
  }
}
