import DistributorRepository from "@/application/repositories/DistributorRepository";

export default class DistributorService {
  private static instance: DistributorService;
  _distributorRepository: DistributorRepository;

  constructor() {
    this._distributorRepository = DistributorRepository.getInstance();
  }

  public static getInstance(): DistributorService {
    if (!DistributorService.instance) {
      // Get from local storage
      DistributorService.instance = new DistributorService();
    }

    return DistributorService.instance;
  }
  
  getAllReferenceUsers(distId: number) {
    return this._distributorRepository.getAllReferenceUsers(distId);
  }

  getById(distId: number) {
    return this._distributorRepository.getById(distId);
  }
}
