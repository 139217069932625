<template>
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 358.06 118.95"
  >
    <polygon
      class="cls-1"
      points="68.04 20.09 59.43 26.43 59.43 93.32 76.65 93.32 76.65 26.43 68.04 20.09"
    />
    <polygon
      class="cls-2"
      points="76.65 23.74 76.65 17.45 68.04 10.95 59.43 17.45 59.43 23.77 59.47 23.77 68.08 17.27 76.65 23.74"
    />
    <polygon
      class="cls-3"
      points="76.65 14.79 76.65 8.41 68.04 1.82 59.43 8.41 59.43 14.82 59.47 14.82 68.08 8.23 76.65 14.79"
    />
    <path
      class="cls-1"
      d="M446.48,618.92l-14.76-16.35a18.19,18.19,0,0,1-8.47-6.08,18,18,0,0,1-3.61-10.41A19.05,19.05,0,0,1,422,576a17.64,17.64,0,0,1,5.93-6.3l.32-.19.19-.12a15.42,15.42,0,0,1,6.41-2.17,16.81,16.81,0,0,1,12.82,4.41,17.27,17.27,0,0,1,5.21,9.61l15.9,15.1a36.66,36.66,0,0,0,1.72-11.1c0-19.46-15.22-35.24-34-35.24s-34.05,15.78-34.05,35.24,15.25,35.25,34.05,35.25a37.34,37.34,0,0,0,7.3-.8C444.94,619.42,445.88,619.12,446.48,618.92Z"
      transform="translate(-188.72 -525.93)"
    />
    <path
      class="cls-4"
      d="M452.88,581.27a17.41,17.41,0,0,1,.4,4,18.78,18.78,0,0,1-6,13.62,17.63,17.63,0,0,1-10.82,4.41c-.64,0-1.3,0-1.3,0a16.12,16.12,0,0,1-3.44-.68l14.76,16.35a35.9,35.9,0,0,0,14.39-9.08,35.33,35.33,0,0,0,7.91-13.47Z"
      transform="translate(-188.72 -525.93)"
    />
    <path
      class="cls-5"
      d="M452.08,578.07a19.64,19.64,0,0,1,1.2,7.2,18.72,18.72,0,0,1-4.4,12,19,19,0,0,1-6.81,4.81,14.82,14.82,0,0,1-5.61,1.2c-.11,0-.64,0-1.3,0a17.65,17.65,0,0,1-7.11-2.36l15.22,18.83a34,34,0,0,0,26-25.63Z"
      transform="translate(-188.72 -525.93)"
    />
    <path
      class="cls-6"
      d="M453.28,584.47a19.1,19.1,0,0,1-2.8,10.82c-.32.51-4.59,7-12.41,8a16.81,16.81,0,0,1-4,0l13.62,15.22a35.82,35.82,0,0,0,13.61-9.22,35.19,35.19,0,0,0,6.81-10.81Z"
      transform="translate(-188.72 -525.93)"
    />
    <path
      class="cls-3"
      d="M419.24,585.61a17.44,17.44,0,0,0,.4,4,16.45,16.45,0,0,0,2,4.8,19.76,19.76,0,0,0,5.61,6,18.07,18.07,0,0,0,2.8,1.6,14.11,14.11,0,0,0,8,1.2,17.35,17.35,0,0,0,7.6-2.8,17.56,17.56,0,0,0,6.41-8,19.05,19.05,0,0,0,.8-12,18.77,18.77,0,0,0-3.6-7.21,17.45,17.45,0,0,0-8.81-5.6,16.74,16.74,0,0,0-9.61.4,17.55,17.55,0,0,0-9.62,8.81A19.77,19.77,0,0,0,419.24,585.61Z"
      transform="translate(-188.72 -525.93)"
    />
    <path
      class="cls-1"
      d="M197.53,525.93h16.82v26.84h26v16.82h-26v20.83a13.39,13.39,0,0,0,10.81,12h15.22v17.22l-16.42-.4a29.77,29.77,0,0,1-26.43-27.63v-22h-8.41q-.21-8.42-.4-16.82h8.81Z"
      transform="translate(-188.72 -525.93)"
    />
    <path
      class="cls-1"
      d="M322.82,578.4l12.41-12.81c-.87-1.31-10.86-15.66-28-15.62-12.38,0-20.58,7.49-22.83,9.61a36.25,36.25,0,0,0-11.22,24.83,35.55,35.55,0,0,0,7.21,22.43c1.36,1.76,10.73,13.48,26.84,13.61,17.26.15,27.12-13.14,28-14.41l-12.41-12.42a18.27,18.27,0,0,1-11.62,9.21,17.21,17.21,0,0,1-16-4.4,17.6,17.6,0,0,1-4.8-12,19.2,19.2,0,0,1,3.6-12c.8-1.08,5.66-7.72,13.62-7.61C314.14,566.88,320.24,571.5,322.82,578.4Z"
      transform="translate(-188.72 -525.93)"
    />
    <path
      class="cls-1"
      d="M351.81,525.93h16.82v26.84h26v16.82h-26v20.83a13.39,13.39,0,0,0,10.82,12h15.21v17.22l-16.42-.4a29.77,29.77,0,0,1-26.43-27.63v-22H343.4q-.19-8.42-.4-16.82h8.81Z"
      transform="translate(-188.72 -525.93)"
    />
    <path
      class="cls-1"
      d="M546.77,585.21c0-3.53-.14-15.91-10-25.63a32.89,32.89,0,0,0-47.26.8l-6.81-8h-4.4v92.52h17.22v-30a32.44,32.44,0,0,0,17.22,6c14.82.62,24.29-9.7,25.63-11.22C546.74,600.21,546.81,589.13,546.77,585.21Zm-18.28,7.21a17.69,17.69,0,0,1-6.41,8,17.39,17.39,0,0,1-7.61,2.8,14.88,14.88,0,0,1-4,0,15.59,15.59,0,0,1-4-1.2,17.62,17.62,0,0,1-2.8-1.6,19.87,19.87,0,0,1-5.61-6,16.18,16.18,0,0,1-2-4.8,16.9,16.9,0,0,1-.4-4,19.63,19.63,0,0,1,2-8.81,17.56,17.56,0,0,1,9.61-8.81,16.58,16.58,0,0,1,9.61-.4,17.43,17.43,0,0,1,8.82,5.6,18.77,18.77,0,0,1,3.6,7.21A19.12,19.12,0,0,1,528.49,592.42Z"
      transform="translate(-188.72 -525.93)"
    />
  </svg>
</template>

<style>
.cls-1 {
  fill: #39499e;
}
.cls-2 {
  fill: #fcb531;
}
.cls-3 {
  fill: #f2cc14;
}
.cls-4,
.cls-5,
.cls-6 {
  fill: #272a6b;
}
.cls-4 {
  opacity: 0.96;
}
.cls-5 {
  opacity: 0.27;
}
</style>
