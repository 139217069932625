import { defineStore } from 'pinia';
import storeId from '@/store/storeId';
import UnAuthenticateService from '@/application/services/un-authenticate/UnAuthenticateService';
import {
  getLocalStorage,
  removeLocalStorage,
  setLocalStorage,
  StorageConstant,
} from '@/ui/hooks/storageHook';

export default defineStore({
  id: storeId.auth,
  state: () => ({
    isLoading: false,
    jwtToken: '' as any,
    refreshToken: '' as any,
    distributorId: '' as any,
    isErrorLogin: false,
    currentPassword: '',
  }),
  getters: {},
  actions: {
    async initData() {
      this.isLoading = true;
      // await Promise.all([this.getLocationViaIP()]);

      this.isLoading = false;
    },
    async authenticate(payload) {
      try {
        this.isLoading = true;
        const { username, password } = payload;
        const response = await UnAuthenticateService.getInstance().authenticate(
          {
            username,
            password,
          }
        );
        if (response?.result) {
          this.isErrorLogin = false;
          this.isLoading = false;
          this.currentPassword = password;
          const { jwtToken, refreshToken, distributorId, userId } =
            response.result;
          this.jwtToken = jwtToken;
          this.refreshToken = refreshToken;
          setLocalStorage(StorageConstant.TOKEN, jwtToken);
          setLocalStorage(StorageConstant.REFRESH_TOKEN, refreshToken);
          setLocalStorage(StorageConstant.DISTRIBUTOR_ID, distributorId);
          setLocalStorage(StorageConstant.USER_ID, userId);
          this.distributorId = getLocalStorage('DISTRIBUTOR_ID');
        } else {
          this.isLoading = false;
        }
        return response.result;
      } catch (error) {
        this.isErrorLogin = true;
        this.isLoading = false;
        console.log('🚀 ~ authenticate ~ error', error);
      }
    },
    checkLogin() {
      this.isErrorLogin = false;
    },
    async logout(data) {
      try {
        const { jwtToken, refreshToken } = data;
        await UnAuthenticateService.getInstance().logout({
          jwtToken,
          refreshToken,
        });
        removeLocalStorage(StorageConstant.TOKEN);
        removeLocalStorage(StorageConstant.REFRESH_TOKEN);
        removeLocalStorage(StorageConstant.DISTRIBUTOR_ID);
        removeLocalStorage(StorageConstant.USER_ID);
        removeLocalStorage('USER_NAME');
      } catch (error) {
        console.log('🚀 ~ logout ~ error', error);
      }
    },
  },
});
