<template>
  <div class="w-full h-full flex-center flex-col z-50">
    <div class="relative h-24 w-24">
      <div class="h-24 w-24 absolute top-0 left-0">
        <div
          class="cricle h-24 w-24 absolute top-0 left-0 transform inline-block"
        >
          <div class="cricle0"></div>
          <div class="cricle1"></div>
          <div class="cricle2"></div>
          <div class="cricle3"></div>
          <div class="cricle4"></div>
          <div class="cricle5"></div>
          <div class="cricle6"></div>
          <div class="cricle7"></div>
          <!-- <svg
            xmlns="http://www.w3.org/2000/svg"
            data-name="Layer 2"
            viewBox="0 0 679 678"
          >
            <circle 
              class="circle0"
              style="fill: #008e8e" 
              cx="45" 
              cy="339" 
              r="45" 
            />
            <circle
              class="circle1"
              style="fill: #008e8e; opacity: 0.2"
              cx="128"
              cy="127"
              r="45"
            />
            <circle
              class="circle2"
              style="fill: #008e8e; opacity: 0.2"
              cx="339"
              cy="40"
              r="45"
            />
            <circle
              class="circle3"
              style="fill: #008e8e; opacity: 0.2"
              cx="551"
              cy="128"
              r="45"
            />
            <circle
              class="circle4"
              style="fill: #008e8e; opacity: 0.2"
              cx="639"
              cy="337"
              r="45"
            />
            <circle
              class="circle5"
              style="fill: #008e8e; opacity: 0.5"
              cx="551"
              cy="551"
              r="45"
            />
            <circle
              class="circle6"
              style="fill: #008e8e; opacity: 0.7"
              cx="341"
              cy="638"
              r="45"
            />
            <circle
              class="circle7"
              style="fill: #008e8e; opacity: 0.7"
              cx="128"
              cy="551"
              r="45"
            />
            
          </svg> -->
        </div>
      </div>
      <div
        class="h-24 w-24 absolute top-0 left-0 rounded-full animate-spin-slow"
      >
        <SynIcon
          name="tictop-logo"
          class="absolute top-0 left-0 transform rotate-90"
          style="width: 2rem; height: 2rem"
        />
      </div>
    </div>

    <div class="flex justify-center pt-16 space-x-2">
      <div class="animate-bounce" style="animation-delay: 0.5s">
        <SynIcon name="character-logo-t" class="h-12 w-8" />
      </div>
      <div class="animate-bounce" style="animation-delay: 1s">
        <SynIcon name="character-logo-i" class="h-12 w-4" />
      </div>
      <div class="animate-bounce" style="animation-delay: 1.5s">
        <SynIcon name="character-logo-c" class="h-12 w-8" />
      </div>
      <div class="animate-bounce" style="animation-delay: 2s">
        <SynIcon name="character-logo-t" class="h-12 w-8" />
      </div>
      <div class="animate-bounce" style="animation-delay: 2.5s">
        <SynIcon name="character-logo-o" class="h-12 w-8" />
      </div>
      <div class="animate-bounce" style="animation-delay: 3s">
        <SynIcon name="character-logo-p" class="h-12 w-8" />
      </div>
    </div>
    <div class="text-current-700 tracking-widest pt-2 text-md">
      Working is fun!
    </div>
  </div>
</template>

<style scoped>
.cricle div {
  transform-origin: 60px 60px;
  animation: cricle 3s linear infinite;
}
.cricle div:after {
  content: ' ';
  display: block;
  position: absolute;
  top: 3px;
  left: 40px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #008e8e;
}
.cricle div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -3s;
}
.cricle div:nth-child(2) {
  transform: rotate(45deg);
  animation-delay: -2.6s;
}
.cricle div:nth-child(3) {
  transform: rotate(90deg);
  animation-delay: -2.2s;
}
.cricle div:nth-child(4) {
  transform: rotate(135deg);
  animation-delay: -1.8s;
}
.cricle div:nth-child(5) {
  transform: rotate(180deg);
  animation-delay: -1.4s;
}
.cricle div:nth-child(6) {
  transform: rotate(225deg);
  animation-delay: -1s;
}
.cricle div:nth-child(7) {
  transform: rotate(270deg);
  animation-delay: -0.6s;
}
.cricle div:nth-child(8) {
  transform: rotate(315deg);
  animation-delay: 0s;
}

@keyframes cricle {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>
