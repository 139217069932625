<template>
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 99.83 100"
  >
    <path
      class="cls-1"
      d="M286.18,75.89v40a40,40,0,0,1-40,40h0v-80a20,20,0,0,1,20-20h0a20,20,0,0,1,20,20Z"
      transform="translate(-246.18 -55.89)"
    />
    <path
      class="cls-2"
      d="M260.12,56.78a20,20,0,0,0-13.94,19.06v2.6A20,20,0,0,0,266,95.89h20.16v-20a20,20,0,0,0-18.6-20H266A19.88,19.88,0,0,0,260.12,56.78Z"
      transform="translate(-246.18 -55.89)"
    />
    <circle class="cls-3" cx="20" cy="19.94" r="16.67" />
    <path
      class="cls-4"
      d="M318.48,103.26h0a4.42,4.42,0,0,1,6-1.62l3.83,2.21A4.43,4.43,0,0,1,330,109.9h0a4.43,4.43,0,0,1-6.05,1.61l-3.83-2.21A4.42,4.42,0,0,1,318.48,103.26Z"
      transform="translate(-246.18 -55.89)"
    />
    <path
      class="cls-5"
      d="M307,117h0a4.41,4.41,0,0,1,6.25,0l3.13,3.12a4.42,4.42,0,0,1,0,6.26h0a4.42,4.42,0,0,1-6.26,0L307,123.25A4.41,4.41,0,0,1,307,117Z"
      transform="translate(-246.18 -55.89)"
    />
    <path
      class="cls-6"
      d="M299.57,139.92h0a4.42,4.42,0,0,1-6.05-1.61h0l-2.22-3.83A4.43,4.43,0,0,1,299,130l2.21,3.84A4.41,4.41,0,0,1,299.57,139.92Z"
      transform="translate(-246.18 -55.89)"
    />
    <path
      class="cls-7"
      d="M267.58,55.89a20,20,0,0,1,18.61,20v20H306a40,40,0,0,0,40-40Z"
      transform="translate(-246.18 -55.89)"
    />
  </svg>
</template>
<style>
.cls-1 {
  fill: #39499e;
}
.cls-2,
.cls-7 {
  fill: #f2cc14;
}
.cls-2 {
  opacity: 0.63;
}
.cls-3 {
  fill: #272a6b;
}
.cls-4 {
  fill: #23a6d9;
}
.cls-4,
.cls-5 {
  isolation: isolate;
}
.cls-5 {
  fill: #147dbf;
}
.cls-6 {
  fill: #2d358b;
}
</style>
