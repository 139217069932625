<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:svgjs="http://svgjs.com/svgjs"
    width="512"
    height="512"
    x="0"
    y="0"
    viewBox="0 0 24 24"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
    class=""
  >
    <g>
      <path
        d="m21.76 12.22-1.42-1.63c.38-.5.6-.86.63-.92.28-.47.12-1.09-.35-1.37s-1.09-.13-1.37.35c-.03.05-2.89 4.75-7.25 4.75-4.36001 0-7.01001-4.5-7.19001-4.81-.14-.31-.42999-.54-.79999-.59-.56-.06-1.04001.33-1.11001.88-.05.4.35999 1.07.80999 1.68l-1.44998 1.66c-.36.42-.32002 1.05.09998 1.41.19.17.42.25.66.25.28 0 .56-.12.75-.34l1.26001-1.44c.52.53 1.12 1.07 1.81 1.56l-1.23999 2.27c-.26.48-.09001 1.09.39999 1.36.15.08.32001.12.48001.12.35 0 .69998-.19.87998-.52l1.22-2.23c.74.33 1.56002.56 2.44002.67v2.05c0 .55.45 1 1 1s1-.45 1-1v-2.04c.88-.11 1.69-.36 2.43-.69l1.23 2.25c.18.33.52.52.88.52.16 0 .33-.04.48-.12.48-.26.66-.87.4-1.36l-1.24-2.27c.71-.49 1.32-1.03 1.84-1.56l1.23 1.41c.2.23.47.34.75.34.23 0 .47-.08.66-.25.42-.36.46-.99.1-1.41z"
      ></path>
    </g>
  </svg>
</template>
