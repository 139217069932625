import DashboardRepository from '@/application/repositories/DashboardRepository';

export default class DashboardService {
  private static instance: DashboardService;
  _dashboardRepository: DashboardRepository;

  constructor() {
    this._dashboardRepository = DashboardRepository.getInstance();
  }

  public static getInstance(): DashboardService {
    if (!DashboardService.instance) {
      // Get from local storage
      DashboardService.instance = new DashboardService();
    }

    return DashboardService.instance;
  }

  getReferenceByDate(data) {
    return this._dashboardRepository.getReferenceByDate(data);
  }

  countReferencePaymentStatus() {
    return this._dashboardRepository.countReferencePaymentStatus();
  }

  getReferenceBySpecificDate(data) {
    return this._dashboardRepository.getReferenceBySpecificDate(data);
  }
}
