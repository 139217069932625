import { translate } from '@/ui/plugins/i18n/myi18n';

export const DATE_OPTIONS = [
  {
    title: translate('COMMON_LABEL_7_DAYS'),
    value: -7,
  },
  {
    title: translate('COMMON_LABEL_15_DAYS'),
    value: -15,
  },
  {
    title: translate('COMMON_LABEL_1_MONTH'),
    value: -30,
  },
  {
    title: translate('COMMON_LABEL_3_MONTHS'),
    value: -90,
  },
  {
    title: translate('COMMON_LABEL_6_MONTHS'),
    value: -180,
  },
];
