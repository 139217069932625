export default function handleQrCode(canvas) {
  const context = canvas.getContext('2d');
  const image = new Image();

  image.src = 'https://d1tvqetrcurhzb.cloudfront.net/common/logo/prod_logo.svg';
  image.crossOrigin = 'anonymous';
  image.onload = () => {
    const coverage = 0.2;
    const width = Math.round(canvas.width * coverage);
    const x = (canvas.width - width) / 2;

    drawImage(context, image, x, x, width, width);
  };

  const drawImage = (context, image, x, y, width, height, radius = 4) => {
    context.shadowOffsetX = 0;
    context.shadowOffsetY = 2;
    context.shadowBlur = 4;
    context.shadowColor = '#00000040';
    context.lineWidth = 8;
    context.beginPath();
    context.moveTo(x + radius, y);
    context.arcTo(x + width, y, x + width, y + height, radius);
    context.arcTo(x + width, y + height, x, y + height, radius);
    context.arcTo(x, y + height, x, y, radius);
    context.arcTo(x, y, x + width, y, radius);
    context.closePath();
    context.strokeStyle = '#fff';
    context.stroke();
    context.clip();
    context.fillStyle = '#fff';
    context.fillRect(x, x, width, height);
    context.drawImage(image, x, x, width, height);
  };
}
