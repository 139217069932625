import BaseRepository from '@/base/repositories/BaseRepository';

export default class DashboardRepository {
  private static instance: DashboardRepository;
  baseRepository: BaseRepository;

  constructor() {
    this.baseRepository = new BaseRepository();
  }

  public static getInstance(): DashboardRepository {
    if (!DashboardRepository.instance) {
      // Get from local storage
      DashboardRepository.instance = new DashboardRepository();
    }

    return DashboardRepository.instance;
  }

  getReferenceByDate(data) {
    return this.baseRepository.requestWithAuthorize({
      url: `Dashboard/GetReferenceByDate`,
      method: 'post',
      data,
    });
  }

  countReferencePaymentStatus() {
    return this.baseRepository.requestWithAuthorize({
      url: `Dashboard/CountReferencePaymentStatus`,
      method: 'get',
    });
  }

  getReferenceBySpecificDate(data) {
    return this.baseRepository.requestWithAuthorize({
      url: `Dashboard/GetReferenceBySpecificDate`,
      method: 'post',
      data,
    });
  }
}
