import BaseRepository from '@/base/repositories/BaseRepository';


export default class DistributorRepository {
    private static instance: DistributorRepository;
    baseRepository: BaseRepository;

    constructor() {
        this.baseRepository = new BaseRepository();
    }

    public static getInstance(): DistributorRepository {
        if (!DistributorRepository.instance) {
            // Get from local storage
            DistributorRepository.instance = new DistributorRepository();
        }

        return DistributorRepository.instance;
    }

    getAllReferenceUsers(distId: number) {
        return this.baseRepository.requestWithAuthorize({
            url: `Distributor/GetAllReferenceUsers?id=${distId}`,
            method: 'get',
        });
    }

    getById(distId: number) {
        return this.baseRepository.requestWithAuthorize({
            url: `Distributor/GetById?id=${distId}`,
            method: 'get',
        });
    }
    //
    // makePayment(data) {
    //     return this.baseRepository.requestWithAuthorize({
    //         url: `Payment/CreatePayUrl`,
    //         method: 'post',
    //         data,
    //     });
    // }
}
