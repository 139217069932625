import PerfectScrollbar from '@/ui/plugins/scrollbar/perfect-scrollbar';

export default {
    install: (app) => {
        app.directive('perfect-scrollbar', {
            mounted(el) {
                if (!el) return;
                el.classList.add('relative', 'overflow-hidden');
                const ps = new PerfectScrollbar(el);

                window.addEventListener('resize', () => {
                    ps?.update();
                });
            },
            //   unmounted() {
            //     console.log('🚀 ~ onUnmounted ~ ps', ps);
            //     ps?.destroy();
            //   },
        });
    },
};
