import alertStore from '../../store/alert';

export enum AlertType {
  success = 'success',
  error = 'error',
  info = 'info',
  warning = 'warning',
}
export enum NotificationPositionType {
  RightBottom = 'right-bottom',
  TopCenter = 'top-center',
  TopCenterRight = 'top-center-right',
  TopRight = 'top-right',
}
export interface IBaseNotification {
  id?: number;
  type?: AlertType;
  title?: string;
  body?: any;
  duration?: number;
  position?: NotificationPositionType;
  metaData?: any;
  notificationValue?: any;
}

export const openNotification = (configs: IBaseNotification) => {
  alertStore().pushDataAlerts({
    type: AlertType.success,
    duration: 5000,
    position: NotificationPositionType.TopCenterRight,
    ...configs,
  });
};
