import { useIntersectionObserver } from '@vueuse/core';
// import listenScrollComposables from '../composables/app/listen-scroll-composables';

export default {
  install: (app) => {
    app.directive('listen-scroll', {
      mounted(el, binding) {
        const { callback, distance } = binding.value;

        const observerElement: any = document.createElement('span');
        el.classList.add('relative');
        observerElement.classList.add('absolute');
        observerElement.style.top = `${distance || 400}px`;
        el.appendChild(observerElement);

        useIntersectionObserver(observerElement, ([{ isIntersecting }]) => {
          observerElement.visible = isIntersecting;
          if (!isIntersecting) return;

          if (callback && typeof callback == 'function') callback();
        });

        // handle scroll
        el.onScrollEvent = () => {
          // if (!observerElement?.visible) return;

          // const st = el?.scrollTop;

          // if (!el?.lastScrollTop) {
          //   el.lastScrollTop = st <= 0 ? 0 : st;
          //   return;
          // }

          // if (st > el?.lastScrollTop) return;

          // el.lastScrollTop = st <= 0 ? 0 : st;
          if (el?.scrollTop !== 0) return;

          if (callback && typeof callback == 'function') callback();
        };
        el.addEventListener('scroll', el.onScrollEvent);
      },
      unmounted(el) {
        el.removeEventListener('scroll', el.onScrollEvent);
      },
    });
  },
};
