import { defineStore } from 'pinia';
import storeId from '@/store/storeId';
import UnAuthenticateService from '@/application/services/un-authenticate/UnAuthenticateService';

export default defineStore({
  id: storeId.SYSTEM,
  state: () => ({
    isLoading: false,
  }),
  getters: {},
  actions: {
    async initData() {
      this.isLoading = true;
      
      this.isLoading = false;
    },
    async getLocationViaIP() {
      try {
        const locationRes =
          await UnAuthenticateService.getInstance().getIpInfo();
        const locationData = locationRes.result;
        const isFrench =
          locationData.country === 'FR' || locationData.countryCode === 'FR';
        const defaultPath =
          process.env.VUE_APP_DOMAIN_DEFAULT?.split('://').pop();
        const isDefaultDomain = window.location.href.includes(
          defaultPath || 'tictop.app'
        );
        if (isFrench && isDefaultDomain) {
          location.href = location.href.replace('tictop.app', 'app.tictop.eu');
        }
      } catch (error) {
        console.log('🚀 ~ getLocationViaIP ~ error', error);
      }
    },
  },
});
