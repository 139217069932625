import { AxiosInstance } from 'axios';
import BaseUnauthorizeRequest from '@/base/api/base-unauthorize-request';

export default class UnauthRepository {
  private static instance: UnauthRepository;
  request: AxiosInstance;
  constructor() {
    this.request = BaseUnauthorizeRequest;
  }
  public static getInstance(): UnauthRepository {
    if (!UnauthRepository.instance) {
      // Get from local storage
      UnauthRepository.instance = new UnauthRepository();
    }

    return UnauthRepository.instance;
  }

  getIpInfo(): Promise<any> {
    return this.request({
      url: 'Setting/GetIpInfo',
      method: 'get',
    });
  }
  
  authenticate(data): Promise<any> {
    return this.request({
      url: 'authenticate',
      method: 'post',
      data,
    });
  }

  refresh(data): Promise<any> {
    return this.request({
      url: 'refresh',
      method: 'post',
      data,
    });
  }

  logout(data): Promise<any> {
    return this.request({
      url: 'logout',
      method: 'post',
      data,
    });
  }
}
