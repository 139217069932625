import BaseRepository from '@/base/repositories/BaseRepository';

export default class UserRepository {
  private static instance: UserRepository;
  baseRepository: BaseRepository;

  constructor() {
    this.baseRepository = new BaseRepository();
  }

  public static getInstance(): UserRepository {
    if (!UserRepository.instance) {
      // Get from local storage
      UserRepository.instance = new UserRepository();
    }

    return UserRepository.instance;
  }

  updateUserProfile(data) {
    return this.baseRepository.requestWithAuthorize({
      url: `User/UpdateUserProfile`,
      method: 'post',
      data,
    });
  }

  updateUserAvatar(data) {
    return this.baseRepository.requestWithAuthorize({
      url: `User/UpdateUserAvatar`,
      method: 'post',
      data,
    });
  }

  genPass(data) {
    return this.baseRepository.requestWithAuthorize({
      url: `User/GenPass`,
      method: 'get',
      data,
    });
  }
  getCurrentUser() {
    return this.baseRepository.requestWithAuthorize({
      url: `User/GetCurrentUser`,
      method: 'get',
    });
  }
  updateUserPassword(data) {
    return this.baseRepository.requestWithAuthorize({
      url: `User/UpdateUserPassword`,
      method: 'post',
      data,
    });
  }
}
