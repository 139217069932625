import UnAuthRepository from '@/application/repositories/UnauthRepository';

export default class UnAuthService {
  private static instance: UnAuthService;
  _unauthRepository: UnAuthRepository;

  constructor() {
    this._unauthRepository = UnAuthRepository.getInstance();
  }

  public static getInstance(): UnAuthService {
    if (!UnAuthService.instance) {
      // Get from local storage
      UnAuthService.instance = new UnAuthService();
    }

    return UnAuthService.instance;
  }
  getIpInfo(): Promise<any> {
    return this._unauthRepository.getIpInfo();
  }

  authenticate(data): Promise<any> {
    return this._unauthRepository.authenticate(data);
  }

  refresh(data): Promise<any> {
    return this._unauthRepository.refresh(data);
  }

  logout(data): Promise<any> {
    return this._unauthRepository.logout(data);
  }
}
