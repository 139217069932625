import { onClickOutside } from '@vueuse/core';

export default {
  install: (app) => {
    app.directive('click-outside', {
      beforeMount(el, binding) {
        onClickOutside(el, (event) => {
          const isThisScope =
            event.target?.classList?.value?.toString().includes('vig') ||
            event.target?.classList?.value?.toString().includes('syn');
          if (isThisScope) return;
          if (
            !(el == event.target || el.contains(event.target)) &&
            typeof binding?.value == 'function'
          ) {
            binding.value(event);
          }
        });
      },
    });
  },
};
